import React, { useState, Fragment } from 'react'
import { useNavigate } from "react-router-dom";
const Contactus = () => {
	const navigate = useNavigate();
	const [status, setStatus] = useState("Submit");
	const [info, setInfo] = useState({
	  name: "",
	  email: "",
	  phone: "",
	  subject: "",
	  message: "",
	});
  
	const handleChange = (e) => {
	  const { name, value } = e.target;
	  setInfo({
		...info,
		[name]: value,
	  });
	};
  
	const handleSubmit = async (e) => {
	  e.preventDefault();
  
	  try {
		let response = await fetch(`/api/contact`, {
		  method: "POST",
		  headers: {
			"Access-Control-Allow-Origin": "*",
			Accept: "application/json",
			"Content-Type": "application/json;charset=utf-8",
		  },
		  body: JSON.stringify(info),
		});
		setStatus("Submitted");
		setInfo({ name: "", email: "", phone: "", subject: "", message: "" });
		let result = await response.json();
		console.log(result);
  
		// Navigate to ThankYou page
		navigate("/thankYou");
	  } catch (error) {
		console.error("Error submitting form:", error);
	  }
	};
	return (
		<Fragment>
			<div className="banner-agile-2">
				<div className="navigation-w3ls">
					<nav className="navbar navbar-expand-lg navbar-light bg-light sticky-nav">
						<button className="navbar-toggler mx-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
							aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse text-center" id="navbarSupportedContent">
							<ul className="navbar-nav justify-content-center">
								<li className="nav-item active">
									<a className="nav-link text-white" href="/">Home
										<span className="sr-only">(current)</span>
									</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/about">About Us</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/allprogram">All Programs</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/Onlineprogram">Online Programs</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/gallery1">Gallery</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/internship">Internship</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/placement">Placement</a>
								</li>
								<li className="nav-item">
									<a className="nav-link text-white" href="/contactus">Contactus</a>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</div>

			<nav aria-label="breadcrumb">
				<ol className="breadcrumb" style={{ fontSize: "14px", fontFamily:"'Open Sans', sans-serif",padding : (".5em",".5em"), backgroundColor: "#e9ecef"}}>
					<li className="breadcrumb-item">
						<a href="/">Home</a>
					</li>
					<li className="breadcrumb-item active" aria-current="page">Contact Us</li>
				</ol>
			</nav>
		<div className="contact py-5">
				<div className="container py-xl-5 py-lg-3">
					<h3 className="title text-capitalize font-weight-light text-dark text-center mb-5">contact
						<span className="font-weight-bold"> us</span>
					</h3>
					<div className="row contact-grids agile-1 py-sm-5 pb-sm-0 pb-5">
						<div className="col-sm-4 contact-grid agileinfo-6 mt-sm-0 mt-2">
							<div className="contact-grid1 text-center">
								<div className="con-ic">
									<i className="fas fa-map-marker-alt rounded-circle"></i>
								</div>
								<h4 className="font-weight-bold mt-sm-4 mt-3 mb-3">Address</h4>
								<p>B133/1, 2nd Floor, KSSIDC ITI Estate
									Whitefield Road, Mahadevpura Post,
									<label>Bangalore-560048</label>
								</p>
							</div>
						</div>
						<div className="col-sm-4 contact-grid agileinfo-6 my-sm-0 my-4">
							<div className="contact-grid1 text-center">
								<div className="con-ic">
									<i className="fas fa-phone rounded-circle"></i>
								</div>
								<h4 className="font-weight-bold mt-sm-4 mt-3 mb-3">Call Us</h4>
								<p>+91 (990) 137 1386
									<label>+91 (990) 050 2404</label>
								</p>
							</div>
						</div>
						<div className="col-sm-4 contact-grid agileinfo-6">
							<div className="contact-grid1 text-center">
								<div className="con-ic">
									<i className="fas fa-envelope-open rounded-circle"></i>
								</div>
								<h4 className="font-weight-bold mt-sm-4 mt-3 mb-3">Email</h4>
								<p>
									<a href="mailto:info@excerptech.com">info@itdeskmahadevpura.com
									</a>
									<label>
										<a href="mailto:excerpttechnologies@gmail.com">itdeskmahadevpura@gmail.com</a>
									</label>
								</p>
							</div>
						</div>
					</div>




					{status !== "Submitted" && (
					<form onSubmit={handleSubmit}>
						<div className="contact-grids1 w3agile-6">
							<div className="row">
								<div className="col-md-6 col-sm-6 contact-form1 form-group">
									<label className="col-form-label" style={{fontSize:"18px"}}>First Name</label>
									<input type="text" id="name" name="name" className="form-control validate[required]" placeholder="First Name" data-errormessage-value-missing="*First Name is required!" required value={info.name} onChange={handleChange} />
								</div>
								
							</div>
							<div className="row">
								<div className="col-md-6 col-sm-6 contact-form1 form-group">
									<label className="col-form-label" style={{fontSize:"18px"}}>Subject</label>
									<input type="text" id="subject" name="subject" className="form-control validate[required]" placeholder="Subject" data-errormessage-value-missing="*Subject is required!" required value={info.subject} onChange={handleChange} />
								</div>
								<div className="col-md-6 col-sm-6 contact-form1 form-group">
									<label className="col-form-label" style={{fontSize:"18px"}}>E-mail</label>
									<input type="email" id="email" name="email" className="form-control validate[required, custom[email]]" placeholder="Email" data-errormessage-value-missing="*Email is required!" required value={info.email} onChange={handleChange} />
								</div>
							</div>
							<div className="contact-me animated wow slideInUp form-group">
								<label className="col-form-label" style={{fontSize:"18px"}}>Message</label>
								<textarea name="message" id="message" className="form-control ht validate[required]" placeholder="Message" data-errormessage-value-missing="*Message is required!" required value={info.message} onChange={handleChange}> </textarea>
							</div>
							<div className="contact-form">
								{/* <input type="submit" value="Submit" >{status}</input> */}
								<button type="submit" id="btnSubmit" className="btn btn_submit" name="landing_register" >{status}</button>
							</div>
						</div>
					</form>

)}










				</div>
			</div>
			
		</Fragment>
	);
};

export default Contactus;
