import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CertificateGenerator from './CertificateGenerator';
import './Certificate.css';
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';
// Import PDFDocument and rgb from pdf-lib
import { TextAlignment } from 'pdf-lib';



function Certificate({ NAME, CollegeName, FATHER_NAME, REG_NO, coursename, certificate_type, yop, coursecertificatr, GENDER, Role }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [selectedCollegeName, setSelectedCollegeName] = useState('');
  const [selectedYOP, setSelectedYOP] = useState('');
  const [selectedCertificateType, setSelectedCertificateType] = useState('');
  const [selectedCourseCertificateType, setSelectedCourseCertificateType] = useState('');
  const [collegeNames, setCollegeNames] = useState([]);
  const [yopList, setYOPList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedRegNo, setSelectedRegNo] = useState('');
  const [enteredDate, setEnteredDate] = useState("");
  const [qrCodeValue, setQrCodeValue] = useState('');
  const [enteredDates, setEnteredDates] = useState({});

  const handleGetCertificate = (student) => {
    setEnteredDates(prevState => ({
      ...prevState,
      [student.REG_NO]: '' // Initialize the date for the specific student
    }));
  };
  const handleChange = (event, regNo) => {
    const { value } = event.target;
    setEnteredDates(prevState => ({
      ...prevState,
      [regNo]: value // Update the date for the specific student
    }));
  };

  const validateDateFormat = (date) => {
    const dateFormat = /^\d{2} (January|February|March|April|May|June|July|August|September|October|November|December) \d{4}$/;
    return dateFormat.test(date);
  };


  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
  };


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/students');
      setStudents(response.data);

      const uniqueCollegeNames = [...new Set(response.data.map(student => student.college_name))];
      setCollegeNames(uniqueCollegeNames);

      // const uniqueYOPs = [...new Set(response.data.map(student => student.yop))];
      // setYOPList(uniqueYOPs);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    filterStudents(selectedCollegeName, selectedYOP, searchText, selectedRegNo);
  }, [students, selectedCollegeName, selectedYOP, searchText, selectedRegNo]);

  const handleCollegeChange = (e) => {
    const selectedCollege = e.target.value;
    setSelectedCollegeName(selectedCollege);
  };

  const handleYOPChange = (e) => {
    const selectedYOP = e.target.value;
    setSelectedYOP(selectedYOP);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleRegNoChange = (e) => {
    setSelectedRegNo(e.target.value);
  };

  const filterStudents = (collegeName, yop, searchText, regNo) => {
    let filtered = students;

    if (collegeName) {
      filtered = filtered.filter(student => student.college_name.toLowerCase() === collegeName.toLowerCase());
    }

    // if (yop) {
    //   filtered = filtered.filter(student => student.yop === yop);
    // }

    if (searchText) {
      const searchLowerCase = searchText.toLowerCase();
      filtered = filtered.filter(student =>
        student.NAME.toLowerCase().includes(searchLowerCase) ||
        student.REG_NO.toLowerCase().includes(searchLowerCase) ||
        student.FATHER_NAME.toLowerCase().includes(searchLowerCase) ||
        student.coursename.toLowerCase().includes(searchLowerCase) ||
        student.certificate_type.toLowerCase().includes(searchLowerCase)
      );
    }

    if (regNo) {
      filtered = filtered.filter(student => student.REG_NO === regNo);
    }

    // Sort the filtered array alphabetically by student name
    filtered.sort((a, b) => a.NAME.localeCompare(b.NAME));

    setFilteredStudents(filtered);
  };

  const capitalize = (str, lower = false) => {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
      match.toUpperCase()
    );
  };


  const getLines = (text, maxWidth, font, fontSize) => {
    const paragraphs = text.split('\n'); // Split text into paragraphs
    let lines = [];
    let y = 210; // Initial y-position

    paragraphs.forEach((paragraph) => {
      const words = paragraph.split(' ');
      let currentLine = '';

      for (let word of words) {
        const width = font.widthOfTextAtSize(currentLine + ' ' + word, fontSize);
        if (width > maxWidth) {
          lines.push(currentLine.trim());
          currentLine = word;
        } else {
          currentLine += ' ' + word;
        }
      }

      if (currentLine !== '') {
        lines.push(currentLine.trim());
      }
    });

    return { lines, y };
  };


  useEffect(() => {
    filterStudents(selectedCollegeName, selectedYOP, searchText, selectedRegNo, selectedCertificateType, selectedCourseCertificateType);
  }, [students, selectedCollegeName, selectedYOP, searchText, selectedRegNo, selectedCertificateType, selectedCourseCertificateType]);





  // const handleGenerateCertificate = async (student) => {
  //   const { NAME: firstName, certificate_type, REG_NO, CollegeName, FATHER_NAME, GENDER, Role, coursename, From, To, Course_Certificate_Type } = student;
  //   const trimmedName = firstName ? firstName.trim() : '';
  //   const trimmedFatherName = FATHER_NAME ? FATHER_NAME.trim() : '';
  //   const registrationNumber = REG_NO ? REG_NO.trim() : '';
  //   // const enteredDate = enteredDates[student.REG_NO];
  //   // if (!validateDateFormat(enteredDate)) {
  //   //   alert('Please enter the date in DD-MM-YYYY format.');
  //   //   return;
  //   // }
  //   if (trimmedName !== '' && certificate_type !== '') {
  //     try {
  //       let templatePath = '';

  //       if (selectedOption === 'PROJECT') {
  //         if (certificate_type.toLowerCase() === 'internship') {
  //           throw new Error('Invalid certificate type for selected option');
  //         } else if (certificate_type.toLowerCase() === 'project') {
  //           templatePath = "./cert5.pdf";
  //         } else {
  //           throw new Error('Invalid certificate type');
  //         }
  //       } else if (selectedOption === 'COURSE') {
  //         if (certificate_type.toLowerCase() === 'internship' || certificate_type.toLowerCase() === 'project') {
  //           templatePath = "./cert11.pdf";
  //         } else {
  //           throw new Error('Invalid certificate type');
  //         }
  //       } else {
  //         throw new Error('Invalid selected option');
  //       }

  //       const existingPdfBytes = await fetch(templatePath).then((res) => res.arrayBuffer());
  //       const mainContentFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const pdfDoc = await PDFDocument.load(existingPdfBytes);
  //       pdfDoc.registerFontkit(fontkit);

  //       const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );

  //       const addFontBytes = await fetch("./NunitoSans_10pt-ExtraBold.ttf").then((res) =>
  //         res.arrayBuffer()
  //       );
  //       const addnameFont = await pdfDoc.embedFont(addFontBytes);



  //       let xPos = 0;

  //       const nameFont = await pdfDoc.embedFont(nameFontBytes);
  //       const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
  //       const pages = pdfDoc.getPages();
  //       const firstPage = pages[0];
  //       let fontSize = 34; // Default font size
  //       let nameHeight = 0; // Default name height
  //       let nameWidth = 0; // Default name width
  //       let nameX = 0; // Default name X position
  //       let nameY = 0; // Default name Y position

  //       if (selectedOption === 'PROJECT') {
  //         fontSize = 20; // Adjust font size for Project
  //         nameHeight = 380; // Adjust name height for Project
  //         nameY = 330; // Adjust name Y position for Project

  //         // Define a fixed center position for the name
  //         const centerX = 423; // Adjust this value as needed
  //         const displayName = capitalize(trimmedName);

  //         // Calculate the width of the entire name
  //         nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);

  //         // Calculate the actual X position for the name
  //         const halfNameWidth = nameWidth / 2;
  //         nameX = centerX - halfNameWidth;

  //         // Draw the entire name at once
  //         firstPage.drawText(displayName, {
  //           x: nameX,
  //           y: nameY,
  //           size: fontSize,
  //           font: nameFont,
  //           color: rgb(0, 0, 0),
  //         });
  //       } else if (selectedOption === 'COURSE') {
  //         fontSize = 12; // Adjust font size for Courses
  //         nameHeight = 360; // Adjust name height for Courses
  //         nameY = 315; // Adjust name Y position for Courses

  //         // Define a fixed starting point for the name
  //         const fixedStartX = 310; // Adjust this value as needed
  //         const displayName = capitalize(trimmedName);

  //         // Calculate the width of the entire name
  //         nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);

  //         // Calculate the actual X position for the name
  //         nameX = fixedStartX;

  //         // Draw the entire name at once
  //         firstPage.drawText(displayName, {
  //           x: nameX,
  //           y: nameY,
  //           size: fontSize,
  //           font: addnameFont,
  //           color: rgb(0, 0, 0),
  //         });
  //       }






  //       // Calculate position based on font size and height
  //       let yPos = nameHeight; // Adjust position based on name height
  //       const displayName = capitalize(trimmedName);
  //       const { width: pageWidth, height: pageHeight } = firstPage.getSize();
  //       nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);

  //       // Draw student name
  //       firstPage.drawText(displayName, {
  //         x: nameX,
  //         y: nameY,
  //         size: fontSize,
  //         font: addnameFont,
  //         color: rgb(0, 0, 0),
  //       });







  //       if (selectedOption === 'PROJECT') {
  //         fontSize = 20; // Adjust font size for Project
  //         nameHeight = 380; // Adjust name height for Project
  //         // Adjust name width if needed
  //         nameX = 200; // Adjust name X position for Project
  //         nameY = 330; // Adjust name Y position for Project

  //         // Additional text for PROJECT
  //         let additionalText = '';
  //         if (certificate_type.toLowerCase() === 'course' || certificate_type.toLowerCase() === 'project') {
  //           if (GENDER.toLowerCase() === 'male') {
  //             additionalText = `This is to certify that MR. ${firstName} has successfully completed their project at "Excerpt IT Training Services",B133/1 2nd Floor, KSSIDC ITI Estate, Whitefield Main Road Mahadevapura, Bengaluru - 560048 from ${From} to ${To}.\n He is exposed to Excerpt IT Training Services and gained the knowledge on "${coursename}" during the project work.`;
  //           } else if (GENDER.toLowerCase() === 'female') {
  //             additionalText = `This is to certify that MS. ${firstName} has successfully completed their project at "Excerpt IT Training Services", B133/1 2nd Floor, KSSIDC ITI Estate, Whitefield Main Road Mahadevpura, Bengaluru - 560048 from ${From} to ${To}.\n She is exposed to Excerpt IT Training Services and gained the knowledge on "${coursename}" during the project work.`;
  //           } else {
  //             additionalText = "This is additional text for other gender students in PROJECT.";
  //           }
  //         }

  //         // Draw additional text
  //         // Draw additional text
  //         if (additionalText !== '') {
  //           const additionalTextFontSize = 11;
  //           const maxLineWidth = 700; // Maximum width of the text
  //           const { lines, y } = getLines(additionalText, maxLineWidth, mainContentFont, additionalTextFontSize);

  //           const lineHeight = additionalTextFontSize * 1.5; // Line height
  //           const totalHeight = lines.length * lineHeight; // Total height of the text block

  //           // Calculate the starting y position to center the text vertically
  //           const additionalTextY = 240 + (totalHeight / 2);

  //           // Calculate the starting x position to center the text horizontally
  //           const pageWidth = firstPage.getSize().width;
  //           const additionalTextOffsetX = 20; // Adjust this value as needed
  //           const additionalTextX = (pageWidth - maxLineWidth) / 2 + additionalTextOffsetX;


  //           let currentY = additionalTextY; // Initialize current Y position
  //           lines.forEach((line, index) => {
  //             const width = mainContentFont.widthOfTextAtSize(line, additionalTextFontSize);
  //             const spacesToAdd = maxLineWidth - width;
  //             const spaces = ' '.repeat(spacesToAdd);
  //             const adjustedLine = line + spaces; // Add spaces to the end of the line
  //             firstPage.drawText(adjustedLine, {
  //               x: additionalTextX,
  //               y: currentY - (index * lineHeight), // Adjust spacing between lines as needed
  //               size: additionalTextFontSize,
  //               font: mainContentFont,
  //               color: rgb(0, 0, 0),
  //               alignment: TextAlignment.flex,
  //             });
  //           });
  //         }
  //       }





  //       if (selectedOption === 'COURSE') {
  //         if (certificate_type.toLowerCase() === 'internship' || certificate_type.toLowerCase() === 'project') {
  //           // Add additional text specific to intership or project certificate
  //           // For example, you can modify the additionalText variable here

  //           const additionalText = ''; // Add your additional text here

  //           if (additionalText !== '') {
  //             const additionalTextX = 190;
  //             const additionalTextFontSize = 11;

  //             // Draw each line of additional content
  //             const { lines, y } = getLines(additionalText, 500, mainContentFont, additionalTextFontSize);
  //             lines.forEach((line, index) => {
  //               pdfDoc.getPages()[0].drawText(line, {
  //                 x: additionalTextX,
  //                 y: y - (index * 10), // Adjust spacing between lines as needed
  //                 size: additionalTextFontSize,
  //                 font: mainContentFont,
  //                 color: rgb(0, 0, 0),
  //                 alignment: TextAlignment.Center,
  //               });
  //             });
  //           }

  //           // Draw common content for COURSE
  //           const regNoText = ` ${REG_NO}`;
  //           const regNoWidth = nameFont.widthOfTextAtSize(regNoText, 12); // Adjust font size as needed
  //           const regNoX = (pageWidth - regNoWidth) / 2.5;
  //           const regNoY = yPos - 274; // Adjust as needed

  //           firstPage.drawText(regNoText, {
  //             x: regNoX,
  //             y: regNoY,
  //             size: 12, // Adjust font size as needed
  //             font: addnameFont,
  //             color: rgb(0, 0, 0),
  //           });

  //           const fatherNameText = FATHER_NAME ? FATHER_NAME.trim() : ''; // Trim father's name
  //           const fatherFontSize = 12; // Font size for father's name
  //           const fatherNameY = yPos - 45; // Adjust Y position as needed

  //           // Calculate the width of the entire father's name
  //           const fatherNameWidth = addnameFont.widthOfTextAtSize(fatherNameText, fatherFontSize);

  //           // Define a fixed starting point for the first letter of the father's name
  //           const fatherFixedStartX = 613; // Adjust this value as needed

  //           // Calculate the actual X position for the father's name
  //           let fatherCurrentX = fatherFixedStartX;

  //           // Draw the father's name letter by letter
  //           for (let i = 0; i < fatherNameText.length; i++) {
  //             const letter = fatherNameText.charAt(i);
  //             const letterWidth = addnameFont.widthOfTextAtSize(letter, fatherFontSize);

  //             // Draw each letter at its respective position
  //             firstPage.drawText(letter, {
  //               x: fatherCurrentX,
  //               y: fatherNameY,
  //               size: fatherFontSize,
  //               font: addnameFont,
  //               color: rgb(0, 0, 0),
  //             });

  //             // Update the current X position for the next letter
  //             fatherCurrentX += letterWidth; // Adjust spacing between letters as needed
  //           }
  //         }

  //         const trainingCenterText = "BANGLORE MAHADEVPURA";
  //         const trainingCenterFontSize = 11; // Font size for Training Center text
  //         const trainingCenterX = 310; // Adjust X position as needed
  //         const trainingCenterY = 172; // Adjust Y position as needed

  //         // Draw the Training Center text on the certificate
  //         firstPage.drawText(trainingCenterText, {
  //           x: trainingCenterX,
  //           y: trainingCenterY,
  //           size: trainingCenterFontSize,
  //           font: addnameFont,
  //           color: rgb(0, 0, 0),
  //         });


  //         const gradeText = " GOOD";
  //         const gradeFontSize = 11; // Font size for Grade text
  //         const gradeX = 610; // Adjust X position as needed
  //         const gradeY = 172; // Adjust Y position as needed

  //         // Draw the Grade text on the certificate
  //         firstPage.drawText(gradeText, {
  //           x: gradeX,
  //           y: gradeY,
  //           size: gradeFontSize,
  //           font: addnameFont,
  //           color: rgb(0, 0, 0),
  //         });
  //         const courseDurationText = "90 Hours"; // Example duration
  //         const courseDurationFontSize = 11; // Font size for Course Duration text
  //         const courseDurationX = 613; // Adjust X position as needed
  //         const courseDurationY = 268; // Adjust Y position as needed

  //         // Draw the Course Duration text on the certificate
  //         firstPage.drawText(courseDurationText, {
  //           x: courseDurationX,
  //           y: courseDurationY,
  //           size: courseDurationFontSize,
  //           font: addnameFont,
  //           color: rgb(0, 0, 0),
  //         });



  //         // Retrieve the Course_Certificate_Type from the student object
  //         const courseCertificateType = student.Course_Certificate_Type;

  //         // Use optional chaining to handle cases where the property may not exist
  //         const courseCertificateTypeText = courseCertificateType ? `${courseCertificateType}` : '';
  //         const certificateFontSize = 11; // Font size for certificate type text

  //         // Calculate the width of the entire certificate type text
  //         const courseCertificateTypeWidth = addnameFont.widthOfTextAtSize(courseCertificateTypeText, certificateFontSize);

  //         // Define a fixed starting point for the first letter of the certificate type text
  //         const certificateFixedStartX = 310; // Adjust this value as needed
  //         const certificateY = yPos - 93; // Adjust Y position as needed

  //         // Calculate the actual X position for the certificate type text
  //         let certificateCurrentX = certificateFixedStartX;

  //         // Draw the certificate type text letter by letter
  //         for (let i = 0; i < courseCertificateTypeText.length; i++) {
  //           const letter = courseCertificateTypeText.charAt(i);
  //           const letterWidth = addnameFont.widthOfTextAtSize(letter, certificateFontSize);

  //           // Draw each letter at its respective position
  //           firstPage.drawText(letter, {
  //             x: certificateCurrentX,
  //             y: certificateY,
  //             size: certificateFontSize,
  //             font: addnameFont,
  //             color: rgb(0, 0, 0),
  //           });

  //           // Update the current X position for the next letter
  //           certificateCurrentX += letterWidth; // Adjust spacing between letters as needed
  //         }

  //         let additionalCourseText = '';
  //         switch (courseCertificateType.toLowerCase()) {
  //           case 'full stack with python':
  //             additionalCourseText = 'HTML, CSS, JavaScript, SQL, Python';
  //             break;
  //           case 'mern stack':
  //             additionalCourseText = 'HTML, CSS, JavaScript, React, MongoDB';
  //             break;
  //           // Add more cases for other course certificate types if needed
  //           default:
  //             // Handle default case or leave it blank if not needed
  //             break;
  //         }

  //         if (additionalCourseText !== '') {
  //           const additionalCourseTextFontSize = 11; // Font size for additional course text
  //           const additionalCourseTextX = 310; // Adjust X position as needed
  //           const additionalCourseTextY = yPos - 110; // Adjust Y position as needed

  //           // Draw the additional course text on the certificate
  //           firstPage.drawText(additionalCourseText, {
  //             x: additionalCourseTextX,
  //             y: additionalCourseTextY,
  //             size: additionalCourseTextFontSize,
  //             font: addnameFont,
  //             color: rgb(0, 0, 0),
  //           });
  //         }



  //         const enteredDateText = enteredDate ? ` ${enteredDate}` : ''; // Modify as needed
  //         const enteredDateFontSize = 11; // Adjust font size as needed
  //         const enteredDateX = 610; // Adjust X position as needed
  //         const enteredDateY = 220; // Adjust Y position as needed

  //         // Draw the entered date on the certificate
  //         firstPage.drawText(enteredDateText, {
  //           x: enteredDateX,
  //           y: enteredDateY,
  //           size: enteredDateFontSize,
  //           font: addnameFont,
  //           color: rgb(0, 0, 0),
  //         });


  //       }


  //       setEnteredDates(prevState => ({
  //         ...prevState,
  //         [student.REG_NO]: '' // Reset the entered date for the specific student
  //       }));

  //       // const qrCodeOptions = {
  //       //   color: {
  //       //     dark: '#000',   // QR code color
  //       //   },
  //       //   errorCorrectionLevel: 'H', // High error correction level
  //       //   width: 200,
  //       //   type: 'png',
  //       //   transparent: true, // Make the background transparent
  //       // };

  //       // const linkURL = `https://itdeskmahadevpura.com/certificate.html?REG_NO=${REG_NO}`;
  //       // setQrCodeValue(linkURL); // Set QR code value to the URL with registration number

  //       // const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
  //       // const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
  //       // const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
  //       // const { width, height } = firstPage.getSize();
  //       // let qrCodeWidth = 100; // Default width
  //       // let qrCodeHeight = 100; // Default height
  //       // let qrCodeX = 50; // Default X position
  //       // let qrCodeY = 50; // Default Y position

  //       // if (selectedOption === 'PROJECT') {
  //       //   // Adjust dimensions and position for Project
  //       //   qrCodeWidth = 85;
  //       //   qrCodeHeight = 85;
  //       //   qrCodeX = 60; // Adjust X position for Project
  //       //   qrCodeY = 50; // Adjust Y position for Project
  //       // } else if (selectedOption === 'COURSE') {
  //       //   // Adjust dimensions and position for Courses
  //       //   qrCodeWidth = 85;
  //       //   qrCodeHeight = 85;
  //       //   qrCodeX = 450; // Adjust X position for Courses
  //       //   qrCodeY = 50; // Adjust Y position for Courses
  //       // }

  //       // // Draw QR code
  //       // firstPage.drawImage(qrCodeImage, {
  //       //   x: qrCodeX,
  //       //   y: qrCodeY,
  //       //   width: qrCodeWidth,
  //       //   height: qrCodeHeight,
  //       // });

  //       const pdfBytes = await pdfDoc.save();
  //       const file = new File(
  //         [pdfBytes],
  //         "Certificate.pdf",
  //         {
  //           type: "application/pdf;charset=utf-8",
  //         }
  //       );

  //       saveAs(file);
  //     } catch (error) {
  //       console.error('Error generating or downloading certificate:', error);
  //       alert('Error generating or downloading certificate. Please try again later.');
  //     }
  //   } else {
  //     alert('Please enter a name and select a certificate type.');
  //   }
  // };


  const handleGenerateCertificate = async (student) => {
    const { NAME: firstName, certificate_type, REG_NO, CollegeName, FATHER_NAME, GENDER, Role, coursename, From, To, Course_Certificate_Type } = student;
    const trimmedName = firstName ? firstName.trim() : '';
    const trimmedFatherName = FATHER_NAME ? FATHER_NAME.trim() : '';
    const registrationNumber = REG_NO ? REG_NO.trim() : '';
    // const enteredDate = enteredDates[student.REG_NO];
    // if (!validateDateFormat(enteredDate)) {
    //   alert('Please enter the date in DD-MM-YYYY format.');
    //   return;
    // }
    if (trimmedName !== '' && certificate_type !== '') {
      try {
        let templatePath = '';

        if (selectedOption === 'PROJECT') {
          if (certificate_type.toLowerCase() === 'internship') {
            throw new Error('Invalid certificate type for selected option');
          } else if (certificate_type.toLowerCase() === 'project') {
            templatePath = "./cert5.pdf";
          } else {
            throw new Error('Invalid certificate type');
          }
        } else if (selectedOption === 'COURSE') {
          if (certificate_type.toLowerCase() === 'internship' || certificate_type.toLowerCase() === 'project') {
            templatePath = "./cert11.pdf";
          } else {
            throw new Error('Invalid certificate type');
          }
        } else {
          throw new Error('Invalid selected option');
        }

        const existingPdfBytes = await fetch(templatePath).then((res) => res.arrayBuffer());
        const mainContentFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
          res.arrayBuffer()
        );
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        pdfDoc.registerFontkit(fontkit);

        const nameFontBytes = await fetch("./Sanchez-Regular.ttf").then((res) =>
          res.arrayBuffer()
        );

        const addFontBytes = await fetch("./NunitoSans_10pt-ExtraBold.ttf").then((res) =>
          res.arrayBuffer()
        );
        const addnameFont = await pdfDoc.embedFont(addFontBytes);



        let xPos = 0;

        const nameFont = await pdfDoc.embedFont(nameFontBytes);
        const mainContentFont = await pdfDoc.embedFont(mainContentFontBytes);
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        let fontSize = 34; // Default font size
        let nameHeight = 0; // Default name height
        let nameWidth = 0; // Default name width
        let nameX = 0; // Default name X position
        let nameY = 0; // Default name Y position

        if (selectedOption === 'PROJECT') {
          fontSize = 20; // Adjust font size for Project
          nameHeight = 380; // Adjust name height for Project
          nameY = 330; // Adjust name Y position for Project

          // Define a fixed center position for the name
          const centerX = 423; // Adjust this value as needed
          const displayName = capitalize(trimmedName);

          // Calculate the width of the entire name
          nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);

          // Calculate the actual X position for the name
          const halfNameWidth = nameWidth / 2;
          nameX = centerX - halfNameWidth;

          // Draw the entire name at once
          firstPage.drawText(displayName, {
            x: nameX,
            y: nameY,
            size: fontSize,
            font: nameFont,
            color: rgb(0, 0, 0),
          });
        } else if (selectedOption === 'COURSE') {
          fontSize = 12; // Adjust font size for Courses
          nameHeight = 360; // Adjust name height for Courses
          nameY = 315; // Adjust name Y position for Courses

          // Define a fixed starting point for the name
          const fixedStartX = 310; // Adjust this value as needed
          const displayName = capitalize(trimmedName);

          // Calculate the width of the entire name
          nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);

          // Calculate the actual X position for the name
          nameX = fixedStartX;

          // Draw the entire name at once
          firstPage.drawText(displayName, {
            x: nameX,
            y: nameY,
            size: fontSize,
            font: nameFont,
            color: rgb(0, 0, 0),
          });
        }






        // Calculate position based on font size and height
        let yPos = nameHeight; // Adjust position based on name height
        const displayName = capitalize(trimmedName);
        const { width: pageWidth, height: pageHeight } = firstPage.getSize();
        nameWidth = nameFont.widthOfTextAtSize(displayName, fontSize);

        // Draw student name
        firstPage.drawText(displayName, {
          x: nameX,
          y: nameY,
          size: fontSize,
          font: nameFont,
          color: rgb(0, 0, 0),
        });







        if (selectedOption === 'PROJECT') {
          fontSize = 20; // Adjust font size for Project
          nameHeight = 380; // Adjust name height for Project
          // Adjust name width if needed
          nameX = 200; // Adjust name X position for Project
          nameY = 330; // Adjust name Y position for Project

          // Additional text for PROJECT
          let additionalText = '';
          if (certificate_type.toLowerCase() === 'course' || certificate_type.toLowerCase() === 'project') {
            if (GENDER.toLowerCase() === 'male') {
              additionalText = `This is to certify that MR. ${firstName} has successfully completed their project at "Excerpt IT Training Services",B133/1 2nd Floor, KSSIDC ITI Estate, Whitefield Main Road Mahadevapura, Bengaluru - 560048 from ${From} to ${To}.\n He is exposed to Excerpt IT Training Services and gained the knowledge on "${coursename}" during the project work.`;
            } else if (GENDER.toLowerCase() === 'female') {
              additionalText = `This is to certify that MS. ${firstName} has successfully completed their project at "Excerpt IT Training Services", B133/1 2nd Floor, KSSIDC ITI Estate, Whitefield Main Road Mahadevpura, Bengaluru - 560048 from ${From} to ${To}.\n She is exposed to Excerpt IT Training Services and gained the knowledge on "${coursename}" during the project work.`;
            } else {
              additionalText = "This is additional text for other gender students in PROJECT.";
            }
          }

          // Draw additional text
          // Draw additional text
          if (additionalText !== '') {
            const additionalTextFontSize = 11;
            const maxLineWidth = 700; // Maximum width of the text
            const { lines, y } = getLines(additionalText, maxLineWidth, mainContentFont, additionalTextFontSize);

            const lineHeight = additionalTextFontSize * 1.5; // Line height
            const totalHeight = lines.length * lineHeight; // Total height of the text block

            // Calculate the starting y position to center the text vertically
            const additionalTextY = 240 + (totalHeight / 2);

            // Calculate the starting x position to center the text horizontally
            const pageWidth = firstPage.getSize().width;
            const additionalTextOffsetX = 20; // Adjust this value as needed
            const additionalTextX = (pageWidth - maxLineWidth) / 2 + additionalTextOffsetX;


            let currentY = additionalTextY; // Initialize current Y position
            lines.forEach((line, index) => {
              const width = mainContentFont.widthOfTextAtSize(line, additionalTextFontSize);
              const spacesToAdd = maxLineWidth - width;
              const spaces = ' '.repeat(spacesToAdd);
              const adjustedLine = line + spaces; // Add spaces to the end of the line
              firstPage.drawText(adjustedLine, {
                x: additionalTextX,
                y: currentY - (index * lineHeight), // Adjust spacing between lines as needed
                size: additionalTextFontSize,
                font: mainContentFont,
                color: rgb(0, 0, 0),
                alignment: TextAlignment.flex,
              });
            });
          }
        }





        if (selectedOption === 'COURSE') {
          if (certificate_type.toLowerCase() === 'internship' || certificate_type.toLowerCase() === 'project') {
            // Add additional text specific to intership or project certificate
            // For example, you can modify the additionalText variable here

            const additionalText = ''; // Add your additional text here

            if (additionalText !== '') {
              const additionalTextX = 190;
              const additionalTextFontSize = 11;

              // Draw each line of additional content
              const { lines, y } = getLines(additionalText, 500, mainContentFont, additionalTextFontSize);
              lines.forEach((line, index) => {
                pdfDoc.getPages()[0].drawText(line, {
                  x: additionalTextX,
                  y: y - (index * 10), // Adjust spacing between lines as needed
                  size: additionalTextFontSize,
                  font: mainContentFont,
                  color: rgb(0, 0, 0),
                  alignment: TextAlignment.Center,
                });
              });
            }

            // Draw common content for COURSE
            const regNoText = ` ${REG_NO}`;
            const regNoWidth = nameFont.widthOfTextAtSize(regNoText, 12); // Adjust font size as needed
            const regNoX = (pageWidth - regNoWidth) / 2.5;
            const regNoY = yPos - 274; // Adjust as needed

            firstPage.drawText(regNoText, {
              x: regNoX,
              y: regNoY,
              size: 12, // Adjust font size as needed
              font: nameFont,
              color: rgb(0, 0, 0),
            });

            const fatherNameText = FATHER_NAME ? FATHER_NAME.trim() : ''; // Trim father's name
            const fatherFontSize = 12; // Font size for father's name
            const fatherNameY = yPos - 45; // Adjust Y position as needed

            // Calculate the width of the entire father's name
            const fatherNameWidth = addnameFont.widthOfTextAtSize(fatherNameText, fatherFontSize);

            // Define a fixed starting point for the first letter of the father's name
            const fatherFixedStartX = 613; // Adjust this value as needed

            // Calculate the actual X position for the father's name
            let fatherCurrentX = fatherFixedStartX;

            // Draw the father's name letter by letter
            for (let i = 0; i < fatherNameText.length; i++) {
              const letter = fatherNameText.charAt(i);
              const letterWidth = addnameFont.widthOfTextAtSize(letter, fatherFontSize);

              // Draw each letter at its respective position
              firstPage.drawText(letter, {
                x: fatherCurrentX,
                y: fatherNameY,
                size: fatherFontSize,
                font: addnameFont,
                color: rgb(0, 0, 0),
              });

              // Update the current X position for the next letter
              fatherCurrentX += letterWidth; // Adjust spacing between letters as needed
            }
          }

          const trainingCenterText = "BANGLORE MAHADEVPURA";
          const trainingCenterFontSize = 11; // Font size for Training Center text
          const trainingCenterX = 310; // Adjust X position as needed
          const trainingCenterY = 172; // Adjust Y position as needed

          // Draw the Training Center text on the certificate
          firstPage.drawText(trainingCenterText, {
            x: trainingCenterX,
            y: trainingCenterY,
            size: trainingCenterFontSize,
            font: addnameFont,
            color: rgb(0, 0, 0),
          });


          const gradeText = " GOOD";
          const gradeFontSize = 11; // Font size for Grade text
          const gradeX = 610; // Adjust X position as needed
          const gradeY = 172; // Adjust Y position as needed

          // Draw the Grade text on the certificate
          firstPage.drawText(gradeText, {
            x: gradeX,
            y: gradeY,
            size: gradeFontSize,
            font: addnameFont,
            color: rgb(0, 0, 0),
          });
          const courseDurationText = "90 Hours"; // Example duration
          const courseDurationFontSize = 11; // Font size for Course Duration text
          const courseDurationX = 613; // Adjust X position as needed
          const courseDurationY = 268; // Adjust Y position as needed

          // Draw the Course Duration text on the certificate
          firstPage.drawText(courseDurationText, {
            x: courseDurationX,
            y: courseDurationY,
            size: courseDurationFontSize,
            font: addnameFont,
            color: rgb(0, 0, 0),
          });



          // Retrieve the Course_Certificate_Type from the student object
          const courseCertificateType = student.Course_Certificate_Type;

          // Use optional chaining to handle cases where the property may not exist
          const courseCertificateTypeText = courseCertificateType ? `${courseCertificateType}` : '';
          const certificateFontSize = 11; // Font size for certificate type text

          // Calculate the width of the entire certificate type text
          const courseCertificateTypeWidth = addnameFont.widthOfTextAtSize(courseCertificateTypeText, certificateFontSize);

          // Define a fixed starting point for the first letter of the certificate type text
          const certificateFixedStartX = 310; // Adjust this value as needed
          const certificateY = yPos - 93; // Adjust Y position as needed

          // Calculate the actual X position for the certificate type text
          let certificateCurrentX = certificateFixedStartX;

          // Draw the certificate type text letter by letter
          for (let i = 0; i < courseCertificateTypeText.length; i++) {
            const letter = courseCertificateTypeText.charAt(i);
            const letterWidth = addnameFont.widthOfTextAtSize(letter, certificateFontSize);

            // Draw each letter at its respective position
            firstPage.drawText(letter, {
              x: certificateCurrentX,
              y: certificateY,
              size: certificateFontSize,
              font: addnameFont,
              color: rgb(0, 0, 0),
            });

            // Update the current X position for the next letter
            certificateCurrentX += letterWidth; // Adjust spacing between letters as needed
          }

          let additionalCourseText = '';
          switch (courseCertificateType.toLowerCase()) {
            case 'full stack with python':
              additionalCourseText = 'HTML, CSS, JavaScript, SQL, Python';
              break;
            case 'mern stack':
              additionalCourseText = 'HTML, CSS, JavaScript, React, MongoDB';
              break;
            // Add more cases for other course certificate types if needed
            default:
              // Handle default case or leave it blank if not needed
              break;
          }

          if (additionalCourseText !== '') {
            const additionalCourseTextFontSize = 11; // Font size for additional course text
            const additionalCourseTextX = 310; // Adjust X position as needed
            const additionalCourseTextY = yPos - 110; // Adjust Y position as needed

            // Draw the additional course text on the certificate
            firstPage.drawText(additionalCourseText, {
              x: additionalCourseTextX,
              y: additionalCourseTextY,
              size: additionalCourseTextFontSize,
              font: addnameFont,
              color: rgb(0, 0, 0),
            });
          }



          const enteredDateText = enteredDate ? ` ${enteredDate}` : ''; // Modify as needed
          const enteredDateFontSize = 11; // Adjust font size as needed
          const enteredDateX = 610; // Adjust X position as needed
          const enteredDateY = 220; // Adjust Y position as needed

          // Draw the entered date on the certificate
          firstPage.drawText(enteredDateText, {
            x: enteredDateX,
            y: enteredDateY,
            size: enteredDateFontSize,
            font: addnameFont,
            color: rgb(0, 0, 0),
          });


        }


        setEnteredDates(prevState => ({
          ...prevState,
          [student.REG_NO]: '' // Reset the entered date for the specific student
        }));

        // const qrCodeOptions = {
        //   color: {
        //     dark: '#000',   // QR code color
        //   },
        //   errorCorrectionLevel: 'H', // High error correction level
        //   width: 200,
        //   type: 'png',
        //   transparent: true, // Make the background transparent
        // };

        // const linkURL = `https://itdeskmahadevpura.com/certificate.html?REG_NO=${REG_NO}`;
        // setQrCodeValue(linkURL); // Set QR code value to the URL with registration number

        // const qrCodeURL = await QRCode.toDataURL(linkURL, qrCodeOptions);
        // const qrCodeImageBytes = Uint8Array.from(atob(qrCodeURL.split('base64,')[1]), c => c.charCodeAt(0));
        // const qrCodeImage = await pdfDoc.embedPng(qrCodeImageBytes);
        // const { width, height } = firstPage.getSize();
        // let qrCodeWidth = 100; // Default width
        // let qrCodeHeight = 100; // Default height
        // let qrCodeX = 50; // Default X position
        // let qrCodeY = 50; // Default Y position

        // if (selectedOption === 'PROJECT') {
        //   // Adjust dimensions and position for Project
        //   qrCodeWidth = 85;
        //   qrCodeHeight = 85;
        //   qrCodeX = 60; // Adjust X position for Project
        //   qrCodeY = 50; // Adjust Y position for Project
        // } else if (selectedOption === 'COURSE') {
        //   // Adjust dimensions and position for Courses
        //   qrCodeWidth = 85;
        //   qrCodeHeight = 85;
        //   qrCodeX = 450; // Adjust X position for Courses
        //   qrCodeY = 50; // Adjust Y position for Courses
        // }

        // // Draw QR code
        // firstPage.drawImage(qrCodeImage, {
        //   x: qrCodeX,
        //   y: qrCodeY,
        //   width: qrCodeWidth,
        //   height: qrCodeHeight,
        // });

        const pdfBytes = await pdfDoc.save();
        const file = new File(
          [pdfBytes],
          "Certificate.pdf",
          {
            type: "application/pdf;charset=utf-8",
          }
        );

        saveAs(file);
      } catch (error) {
        console.error('Error generating or downloading certificate:', error);
        alert('Error generating or downloading certificate. Please try again later.');
      }
    } else {
      alert('Please enter a name and select a certificate type.');
    }
  };






  return (
    <>


      <div>


        <select onChange={handleDropdownChange} style={{ marginLeft: "-1200px", marginTop: "190px" }}>
          <option value="">Select an option</option>
          <option value="PROJECT">PROJECT</option>
          <option value="COURSE">COURSE</option>
        </select>

        <div className='container' style={{
          height: "100px", marginLeft: "-100px", display: "flex",
          alignItems: "center"
        }}>

          <select id="collegeDropdown" value={selectedCollegeName} onChange={handleCollegeChange} style={{ marginTop: "-140px", marginLeft: "300px" }}>
            <option value="">CollegeName</option>
            {collegeNames.map((collegeName, index) => (
              <option key={index} value={collegeName}>{collegeName}</option>
            ))}
          </select>
        </div>
        <div className='container' style={{
          height: "100px", marginLeft: "-80px", display: "flex",
          alignItems: "center"
        }}>
          {/* <select id="yopDropdown" value={selectedYOP} onChange={handleYOPChange} style={{ marginLeft: "550px", marginTop: "-340px" }}>
            <option value="">YOP</option>
            {yopList.map((yop, index) => (
              <option key={index} value={yop}>{yop}</option>
            ))}
          </select> */}
        </div>

        <div className='container search-container' style={{
          height: "100px", marginLeft: "430px", display: "flex", marginTop: "-90px",
          alignItems: "center"
        }}>

          <input
            type="text"
            className='search-input'
            placeholder="Search..."
            value={searchText}
            onChange={handleSearchChange}
            style={{ marginLeft: "-1640px", marginTop: "-350px" }}
          />
          <i className="fa fa-search search-icon"></i>
        </div>

        <div className='container search-container' style={{
          height: "100px", marginLeft: "730px", display: "flex", marginTop: "-100px",
          alignItems: "center"
        }}>

          <input
            type="text"
            className='search-input'
            placeholder="Enter REG_NO..."
            value={selectedRegNo}
            onChange={handleRegNoChange}
            style={{ marginLeft: "50px", marginTop: "-350px" }}
          />
        </div>




        {selectedOption === 'PROJECT' && (
          <div>
            <p></p>
            <div className='card_student_container' style={{ marginLeft: "-160px" }}>
              {filteredStudents.map((student, index) => (
                <div key={index}>
                  <CertificateGenerator
                    firstName={student.NAME}
                    GENDER={student.GENDER}
                    CollegeName={student.college_name}
                    FATHER_NAME={student.FATHER_NAME}
                    REG_NO={student.REG_NO}
                    coursename={student.coursename}
                    certificate_type={student.certificate_type}
                    yop={student.yop}
                    coursecertificatr={student.Course_Certificate_Type}
                    Role={student.Role}
                    From={student.From}
                    To={student.To}

                  />
                  <button onClick={() => handleGenerateCertificate(student)} style={{ marginLeft: "30px", width: "200px", marginTop: "10px" }}>Get Certificate1</button>
                  <a href={`https://itdeskmahadevpura.com//certificate.html?REG_NO=${student.REG_NO}`} target="_blank">view</a>
                </div>
              ))}
            </div>


          </div>
        )}




        {selectedOption === 'COURSE' && (
          <div>
            <p></p>

            <div className='card_student_container' style={{ marginLeft: "-160px" }}>
              {filteredStudents.map((student, index) => (
                <div key={index}>
                  <CertificateGenerator
                    firstName={student.NAME}
                    GENDER={student.GENDER}
                    CollegeName={student.college_name}
                    FATHER_NAME={student.FATHER_NAME}
                    REG_NO={student.REG_NO}
                    coursename={student.coursename}
                    certificate_type={student.certificate_type}
                    yop={student.yop}
                    coursecertificatr={student.Course_Certificate_Type}
                    Role={student.Role}
                    From={student.From}
                    To={student.To}
                    REG_NO1={student.REG_NOO}

                    enteredDate={student.enteredDate}
                  />

                  {enteredDates[student.REG_NO] !== undefined && (
                    <div>
                      <input
                        type="text"
                        placeholder="Enter Date (DD MM YYYY)"
                        onChange={(event) => handleChange(event, student.REG_NO)}
                        value={enteredDates[student.REG_NO]}
                        style={{ marginBottom: "10px" }}
                      />
                      <button onClick={() => handleGenerateCertificate(student)}>Confirm</button>
                      <a href={`https://itdeskmahadevpura.com/certificate.html?REG_NO=${student.REG_NO}`} target="_blank">view</a>
                    </div>
                  )}

                  {enteredDates[student.REG_NO] === undefined && (
                    <button onClick={() => handleGetCertificate(student)}>Get Certificate</button>

                  )}
                </div>
              ))}
            </div>


          </div>
        )}
      </div>


    </>
  );
}

export default Certificate;
