import React, { useState, useEffect, Fragment } from 'react';
import { Navigate } from 'react-router-dom';

const Header = ({ isLoggedIn, onLogout }) => {


	const [showLogoutToast, setShowLogoutToast] = useState(false);

	useEffect(() => {
		// Check if user is logged in from sessionStorage
		const userLoggedIn = sessionStorage.getItem('isLoggedIn');
		if (userLoggedIn === 'true') {
			// Set isLoggedIn prop if user is logged in
			isLoggedIn = true;
		}
	}, []);

	const handleLogout = () => {
		// Perform logout actions
		onLogout();
		setShowLogoutToast(true);
		// Hide the toast after 3 seconds
		setTimeout(() => {
			setShowLogoutToast(false);
			// Clear login status from sessionStorage
			sessionStorage.setItem('isLoggedIn', 'false');
		}, 3000);
	};

	return (
		<Fragment>

			<header>

				<div className="top-head-w3ls py-2 bg-dark">
					<div className="container">
						<div className="row">
							<h1 className="text-capitalize text-white col-7">
								<i className="fas fa-book text-dark bg-white p-2 rounded-circle mr-3"></i>welcome to IT DESK MAHADEVAPURA</h1>

							<div className="social-icons text-right col-5">
								<ul className="list-unstyled">
									<li>
										<a href="https://www.facebook.com/ITDESKMahadevpura/" className="fab fa-facebook-f icon-border facebook rounded-circle"> </a>
									</li>
									<li className="mx-2">
										<a href="https://www.instagram.com/it_desk_cad_desk_mahadevpura/?igshid=MzRlODBiNWFlZA%3D%3D" className="fab fa-instagram icon-border instagram rounded-circle"> </a>

									</li>
									<li>
										<a href="https://itdeskmahadevpura.com/" className="fab fa-google-plus-g icon-border googleplus rounded-circle"> </a>
									</li>
									<li className="ml-2">
										<a href="/" className="fab fa-youtube icon-border youtube rounded-circle"> </a>

									</li>
								</ul>
							</div>

						</div>
					</div>
				</div>


				<div className="middle-w3ls-nav py-4">
					<div className="container">
						<div className="row">


							<div className="col-lg-12 right-info-agiles mt-lg-0 mt-sm-3 mt-1">
								<div className="row">
									<div className="col-sm-3 nav-middle">

										<div className="agile-addresmk">
											<a href="/">
												<img src="assests/images/logo.jpg" alt="IT Desk Logo" style={{ width: '140px' }} />
											</a>
										</div>

									</div>
									<div className="col-sm-4 nav-middle">
										<i className="far fa-envelope-open text-center mr-md-4 mr-sm-2 mr-4"></i>
										<div className="agile-addresmk">
											<p>
												<span className="font-weight-bold text-dark">Mail Us</span>
												<a href="info@itdeskmahadevpura.com">info@itdeskmahadevpura.com</a>
											</p>
										</div>
									</div>
									<div className="col-sm-3 nav-middle mt-sm-0 mt-2">
										<i className="fas fa-phone-volume text-center mr-md-4 mr-sm-2 mr-4"></i>
										<div className="agile-addresmk">
											<p>
												<span className="font-weight-bold text-dark">Call Us</span>
												+91 9901371386/9900502404
											</p>
										</div>
									</div>
									<div className="upper_login" style={{fontSize:"15px"}}>
										{isLoggedIn ? (
											<a href="#" class="user-account font-weight-bold" onClick={handleLogout}>
												<i class="fas fa-sign-out-alt "></i> Logout
											</a>
										) : (
											<a href="/login" class="user-account font-weight-bold">
												<i class="fas fa-solid fa-user-tie font-weight-bold text-dark"></i> Login / Register
											</a>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{showLogoutToast && (
					<div className="logout-toast" style={{ position: 'fixed', top: '10px', right: '10px', backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white', padding: '10px', borderRadius: '5px' }}>
						Logout successful
					</div>
				)}
			</header>


		</Fragment>
	)
}

export default Header



